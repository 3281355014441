<template>
  <router-link
    class="w-100 mb-2 d-block"
    :to="
      `/homologation/${service.homologation}/service/${service.id}/${user.role}/validation`
    "
  >
    <b-card body-class="w-100 py-3" class="card-hoverable">
      <div class="w-100">
        <div
          class="d-flex 
            align-items-start
            justify-content-between"
        >
          <section>
            <h6 class="pointer">
              {{ service.scope }}
            </h6>
            <b-badge
              class="d-table"
              :variant="getStatus(service.status).variant"
            >
              <span>{{ getStatus(service.status).label }}</span>
            </b-badge>
          </section>
          <div
            v-if="service.status === 0 || !user.is_admin && service.status === 1"
            class="
            d-flex
            align-items-center 
            actions-service
            gap-3
          "
            v-permission="'action'"
          >
            <b-button
              v-permission="'service.change_service'"
              :to="pathService(service)"
              size="sm"
              class="px-2 line-height-1"
              variant="outline-dark"
            >
              <b-icon icon="pencil" class="mr-1 text-muted" />
              <span class="text-muted">Editar</span>
            </b-button>
            <b-button
              v-if="user.is_admin"
              v-permission="'service.delete_service'"
              @click.prevent="handleConfirmDelete(service.id)"
              v-b-popover.hover.top="'Excluir'"
              size="sm"
              class="px-2 line-height-1"
              variant="outline-dark"
            >
              <b-icon icon="trash" class="h6 mx-1 mb-0 text-muted" />
            </b-button>
          </div>
        </div>
        <hr />
        <section class="text-muted">
          <div class="mr-3">
            <b-icon icon="calendar" class="mr-1" />
            <span>{{ service.date_init | date }}</span>
          </div>
          <div>
            <b-icon icon="calendar-check" class="mr-1" />
            <span>{{ service.date_end | date }}</span>
          </div>
        </section>

        <b-icon
          icon="arrow-right"
          class="h4 text-dark mx-1 mb-0 icon-go d-block d-md-none"
        />
      </div>
      <hr />
      <section class="text-muted">
        <section>
          <b-icon icon="cash" variant="success" class="mr-1" />
          <span>Mensal: {{ service.contract_value_mensal | money }}</span>
        </section>

        <section>
          <b-icon icon="cash" variant="success" class="mr-1" />
          <span>Total: {{ service.contract_value_total | money }}</span>
        </section>
      </section>
      <section class="mb-2 text-muted">
        <chart
          width="110"
          type="donut"
          :options="chartOptions"
          :series="[service.dependency_limit_perc, 100]"
        />
        <span>
          Máximo {{ service.dependency_limit_perc }}% de dependência
        </span>
      </section>
    </b-card>
  </router-link>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    service: {
      type: Object,
      require: true,
    },
    selectedService: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          width: 180,
          type: "bar",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 100,
            options: {
              chart: {
                width: 100,
              },
              legend: {
                show: true,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
        fill: {
          colors: ["#347cff", "#ececec"],
        },
        labels: ["Dependência", " "],
      },
    };
  },
  computed: {
    ...mapGetters(["user", "is_provider"]),
  },
  methods: {
    ...mapActions(["delete_service_scope", "show_delete"]),

    getStatus(status = 0) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 0:
          variant = "info";
          label = "Não inicializado";
          break;
        case 1:
          variant = "secondary";
          label = "Em progresso";
          break;
        case 2:
          variant = "success";
          label = "Finalizado";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },

    handleConfirmDelete(id) {
      this.selectedId = id;
      this.show_delete({
        in_delete: true,
        message:
          "Você apagará a homologação e todos os serviços vinculados a ela. Deseja continuar?",
      });
    },

    handleDeleteService() {
      this.delete_service_scope(this.selectedId).then(() => {
        this.show_delete({ in_delete: false });
        this.$emit("update");
      });
    },
    pathService(service){
      let path = `/homologation/${service.homologation}/service/${service.id}/edit/`
      let url
      if (service.status === 0){
        path += 'details/'
      } else {
        path += 'invites/'
      }
      return path
    }
  },
  created() {
    this.$root.$on("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.handleDeleteService();
      }
    });
  },
};
</script>

<style lang="scss">
.actions-service {
  @media (max-width: $break-md) {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.icon-go {
  display: none;
  @media (max-width: $break-md) {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}
</style>
